import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SectionNoBG from "../components/section_no_bg"
import arrow from "../images/arrow.svg"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <SectionNoBG
      classes="first-section"
      headline="404: Not Found"
      text="You just hit a route that doesn&#39;t exist... the sadness."
    >
      <Link
        to="/"
        target="_blank"
        rel="noreferrer"
        className=" arrow-button mt-4 me-5 d-block d-md-inline-block"
      >
        <h5 className="mb-0 hover-show">Back to home</h5>
        <img
          alt="arrow"
          className="mx-3 theme-fg-color"
          src={arrow}
          width="36"
        />
      </Link>
    </SectionNoBG>
  </Layout>
)

export default NotFoundPage
